<template>
  <b-card class="p-1">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <b-form-group class="w-50 mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Wyszukaj po nazwie" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Wyczyść </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button
        :to="{ name: 'navigation-create' }"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="btn">
        Dodaj
      </b-button>
    </div>
    <b-table
      responsive
      bordered
      striped
      hover
      :items="navigationGroups"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn">
      <template #cell(actions)="row">
        <div class="d-flex flex-column">
          <b-button
            :to="{ name: 'navigation-edit', params: { id: row.item.id } }"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="btn table-btn"
            size="sm">
            Edytuj
          </b-button>
          <b-button class="mt-1 table-btn" variant="danger" size="sm" @click="destroy(row.item)"> Usuń </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { all, destroy } from '@/http/navigation';

export default {
  mixins: [NotificationMixin],

  data() {
    return {
      navigationGroups: [],
      filter: '',
      filterOn: [],
      fields: [
        {
          key: 'name',
          label: 'Nazwa grupy',
          sortable: true,
          sortDirection: 'desc',
        },

        { key: 'actions', label: 'Akcje' },
      ],
    };
  },

  async mounted() {
    await this.fetchNavGroups();
  },

  methods: {
    async fetchNavGroups() {
      try {
        const { data } = await all({
          lang: 'pl',
        });

        this.navigationGroups = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    destroy(navGroup) {
      this.$swal({
        title: 'Jesteś pewny?',
        text: 'Czy chcesz usunąć ten element nawigacji?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Usuń',
        cancelButtonText: 'Anuluj',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          destroy(navGroup.id)
            .then(() => {
              this.showSuccessNotification('Element nawigacji usunięty', 'Pomyślnie usunięto element nawigacji.');
              this.fetchNavGroups();
            })
            .catch(() => {
              this.showErrorNotification(
                'Problem z usunięciem danych',
                'Wystąpił błąd podczas usuwania elementu nawigacji. Skontaktuj się ze swoim developerem.',
              );
            });
        }
      });
    },
  },
};
</script>
