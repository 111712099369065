<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  methods: {
    showSuccessNotification(title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'CheckIcon',
          variant: 'success',
          text,
        },
      });
    },

    showErrorNotification(title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text,
        },
      });
    },

    showFormValidationNotification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Błędnie uzupełnione pola',
          icon: 'EditIcon',
          variant: 'danger',
          text: 'Sprawdź poprawność uzupełnionych pól.',
        },
      });
    },
  },
};
</script>
