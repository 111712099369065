import axios from '../axios';

export const all = (params = {}) => {
  return axios.get('/navigation', { params });
};

export const updateOrCreate = (payload) => {
  return axios.post('/navigation', payload);
};

export const show = (id, params = {}) => {
  return axios.get(`/navigation/${id}`, { params });
};

export const destroy = (id) => {
  return axios.delete(`/navigation/${id}`);
};

export const updateDescription = (payload) => {
  return axios.post('/navigation/update-description', payload);
};
